import React from "react";
import "./feature_list.css";

export default function FeatureList({src, alt, title, details}) {
  return(
    <div className="feature-list d-flex">
      <div>
        <img src={src} alt={alt} />
      </div>
      <div className="ml-2">
        <h5>{title}</h5>
        <p>{details}</p>
      </div>
    </div>
  )
}