import React from "react";
import "./cta.css";

export default function CTA() {
  return(
    <section className="cta-wrapper">
      <div>
        <h4>Receive Money With Ease From Passengers</h4>
        <p>Receive VPay ride payments from your passengers with a click on their phone.</p>
        <a href="https://t.me/vpay_driver_bot" className="btn btn-register">Register</a>
      </div>
    </section>
  )
}