import React from "react";
import Step from "./step/step";
import StepOne from "../../../assets/images/step-one.png";
import StepTwo from "../../../assets/images/step-two.png";
import StepThree from "../../../assets/images/step-three.png";
import "./instructions.css";

export default function Instructions() {
  return(
    <section className="instructions-wrapper">
      <h4>
        Tap <a href="https://t.me/vpay_passenger_bot" target="_blank" rel="noreferrer noopener">here</a> to open our Telegram chat assistant 
        (you will be required to download the Telegram chat app if you don't have it already)
      </h4>
      <div>
        <Step
          title="Step 1"
          details="Press 1 to create your VPay Ride account. Follow the prompts on the Telegram page to provide your name and phone number."
        />
        <div>
          <img src={StepOne} className="img-fluid" alt="" />
        </div>
      </div>
      <div>
        <div>
          <img src={StepTwo} className="img-fluid" alt="" />
        </div>
        <Step
          title="Step 2"
          details="Upload a selfie picture of your face as this is what is shown on the payment receipt."
        />
      </div>
      <div>
        <Step
          title="Step 3"
          details="Get a unique account number attached to your VPay Ride Account. Once the VPay Ride Account is fully funded, you can easily send instant payments to the vehicle driver."
        />
        <div>
          <img src={StepThree} className="img-fluid" alt="" />
        </div>
      </div>
    </section>
  )
}