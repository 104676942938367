import React from 'react';
import Footer from '../../components/footer/footer';
import CTA from '../../components/home/cta/cta';
import Features from '../../components/home/features/features';
import HeroPage from '../../components/home/hero/hero_section';
import Instructions from '../../components/home/instructions/instructions';
import PreFooter from '../../components/pre_footer/pre_footer';

export default function Home() {
  return(
    <>
      <HeroPage />
      <Features />
      <Instructions />
      <CTA />
      <PreFooter />
      <Footer />
    </>
  )
}