import React from "react"; 
import Navbar from "../../navbar/navbar";
import HeroMobileImage from "../../../assets/images/hero-mobile-image.png";
import logo from "../../../assets/images/logo.svg";
import "./hero_section.css";

export default function HeroSection() {
  return(
    <header className="header">
      <Navbar logo={logo} className="btn-start-transparent" />
      <div>
        <h1>The Smarter Way To Pay For Your Rides</h1>
        {/* <p>Payment is easier with VPay Ride</p> */}
        <a href="https://t.me/vpay_passenger_bot" target="_blank" rel="noreferrer" className="btn get-started-dark">Get Started</a>
      </div>
      <img src={HeroMobileImage} className="no-desktop img-fluid w-100" alt="" />
    </header>
  )
}