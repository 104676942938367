import React, { useState } from "react";
import MenuIcon from "../../assets/images/hamburger.svg";
import "./navbar.css";

export default function Navbar({ logo, className }) {
  const [show, setShow] = useState(false);

  return(
    <nav className="d-flex j-space-between align-items-center">
      <div>
        <img src={logo} className="img-fluid" alt="company's logo" />
      </div>
      <div className="no-mobile">
        <a href="https://t.me/vpay_passenger_bot" target="_blank" rel="noreferrer" className={`btn ${className}`}>Get Started</a>
      </div>
      <div className="no-desktop">
        <button className="transparent" onClick={() => setShow(!show)}>
          <img src={MenuIcon} alt="menu icon" />
        </button>
      </div>
      {show && (
        <div className="menu-wrapper">
          <a href="https://t.me/vpay_passenger_bot" target="_blank" rel="noreferrer" className="btn btn-register">Get Started</a>
        </div>
      )}
    </nav>
  )
}