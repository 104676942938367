import React from "react";
import facebook from "../../assets/images/facebook.svg";
import instagram from "../../assets/images/instagram.svg";
import twitter from "../../assets/images/twitter.svg";
import linkedin from "../../assets/images/linkedin.svg";
import "./footer.css";

export default function Footer() {
  const year = new Date().getUTCFullYear();
  
  return(
    <footer className="footer">
      <div className="d-flex j-space-between pb-2">
        <div className="links">
          <a href="https://vpay.africa/terms-and-condition" target="_blank" rel="noreferrer">
            Terms of Service
          </a>
          <a href="https://vpay.africa/terms-and-condition" target="_blank" rel="noreferrer">
            Privacy policy
          </a>
          <a href="https://vpay.africa/contact" target="_blank" rel="noreferrer">
            Contact
          </a>
        </div>
        <div className="social-links">
          <a href="https://web.facebook.com/Vpayafrica-109354711228898/" target="_blank" rel="noreferrer">
            <img src={facebook} className="ml-2" alt="" />
          </a>
          <a href="https://www.instagram.com/myvpayafrica/" target="_blank" rel="noreferrer">
            <img src={instagram} className="ml-2" alt="" />
          </a>
          <a href="https://twitter.com/vpay_africa" target="_blank" rel="noreferrer">
            <img src={twitter} className="ml-2" alt="" />
          </a>
          <a href="https://www.linkedin.com/company/vpayafrica" target="_blank" rel="noreferrer">
            <img src={linkedin} className="ml-2" alt="" />
          </a>
        </div>
      </div>
      <hr/>
      <p>
        ©{year} VPay. All rights reserved
      </p>
    </footer>
  )
}