import React from "react";
import AppStore from "../../assets/images/appstore.svg";
import PlayStore from "../../assets/images/playstore.svg";
import star from "../../assets/images/star.svg";
import vector from "../../assets/images/vector.svg";
import "./pre_footer.css";

export default function PreFooter() {
  return(
    <section className="prefooter">
      <img src={star} className="star no-mobile" alt="" />
      <h4>All-in-one Platform for business</h4>
      <p>Sign up today and start receiving <strong>bank</strong> transfer payments from over of 50% of your untapped customer base, transaction notifications are instant.</p>
      <div>
        <a href="https://merchantapp.vpay.africa/"><img src={AppStore} className="img-fluid mx-05" alt="" /></a>
        <a href="https://merchantapp.vpay.africa/"><img src={PlayStore} className="img-fluid mx-05" alt="" /></a>
      </div>
      <img src={vector} className="vector no-mobile" alt="" />
    </section>
  )
}