import React from "react";
import feature from "../../../assets/images/feature.svg";
import FeatureList from "./feature_list/feature_list";
import "./features.css";

const FeaturesList = [
  {
    id: 1,
    src: feature,
    alt: "an icon",
    title: "Cashless & Contactless Payment",
    details: "Imagine going on a bus without cash! Why worry about moving around with cash when you can just make an easy transfer from your mobile device? With VPay Ride & Share there is no need to handle cash."
  },
  {
    id: 2,
    src: feature,
    alt: "an icon",
    title: "Seamless Experience",
    details: "Save yourself the unnecessary inconvenience of splitting change or leaving your money with other bus passengers."
  },
  {
    id: 3,
    src: feature,
    alt: "an icon",
    title: "Time Effective",
    details: "Best of all, you do not have to waste time when you can make your VPay Ride payment in about 10 seconds."
  }
]

export default function Features() {
  return(
    <section className="features-wrapper">
      <h2>Features for a better experience</h2>
      <div className="list-wrapper">
        {FeaturesList.map(feature => (
          <FeatureList
            key={feature.id}
            src={feature.src} 
            alt={feature.alt}
            title={feature.title}
            details={feature.details}
          />
        ))}
      </div>
      <hr />
      <div className="video-wrapper">
        <div>
          <h3>How It Works</h3>
          <p>Watch this to know how the VPay Ride works to make payments seamlessly.</p>
          <div className="milestone d-flex align-items-center">
            <div className="d-flex align-items-center mr-2">
              <div className="mr-1">
                <h4>99%</h4>
              </div>
              <div>
                <p>Customer <br/> Support</p>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="mr-1">
                <h4 style={{ color: "#F3000B"}}>1947</h4>
              </div>
              <div>
                <p>Completed <br/> Rides</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <iframe
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/uWT8bdV2DNs" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            ></iframe>
        </div>
      </div>
    </section>
  )
}