import React from "react";
import "./step.css";

export default function Step({title, details}) {
  return(
    <div className="step-instruction">
      <h4>{title}</h4>
      <p>{details}</p>
    </div>
  )
}